<template>
  <div v-if="ready">
    <div class="card card-custom card-nobody gutter-b">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">{{this.$store.back.getText('fiche-projet')}} #{{projet.id}}</h3>
        </div>
        <div class="card-toolbar div-center">
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="primary" size="sm" @click="toggleFiles = !toggleFiles" class="btn-icon mr-1"><i class="icon-md fas fa-file"></i></b-button>
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save" v-if="$store.getters.currentUser.role === 1">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3" :class="toggleFiles ? '' : 'd-none'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('liste-fichier')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="primary" size="sm" class="btn-icon mr-1" v-b-modal.import><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-row>
            <b-button size="md" variant="success" @click="docs_filter = null" class="ml-5 mr-1">Tout</b-button>
            <b-button size="md" variant="primary" class="mr-1" v-for="(t,i) in params.GEDType"  @click="docs_filter = t.id"  :key="i">{{t.titre.fr}}</b-button>
          </b-row>
          <b-row class="mt-2">
            <b-col md="2" v-for="(d,i) in docsFiltered" :key="i">
              <div class="card text-center border-primary">
                <div class="card-header bg-primary text-white" style="padding: 1rem 1.25rem;">{{getFileName(d)}}</div>
                <div class="card-body" style="padding:1.5rem;">
                  <h6 class="card-subtitle text-muted mb-2">{{params.GEDType.find(t => t.id === d.ged_type).titre.fr}}</h6>
                  <h6 class="card-subtitle text-muted mb-2 mt-1">{{$store.back.timestampToDate(d.date_create,false)}}</h6>
                  <b-card-text>
                    <b-button-group>
                      <b-button size="sm" variant="primary" :href="d.url" target="_blank"><i class="fas fa-eye"></i></b-button>
                      <b-button size="sm" variant="danger" @click="removeFile(i)"><i class="fas fa-minus"></i></b-button>
                    </b-button-group>
                  </b-card-text>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('info-projet')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('last_name')+':'">
                <b-form-input v-model="projet.titre" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="this.$store.back.getText('type')+':'">
                <treeselect v-model="projet.type_id" :multiple="true" :options="params.ProjetType" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('constructeur')+':'">
                <b-form-input v-model="projet.constructeur"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="this.$store.back.getText('date-livraison')+':'">
                <b-row>
                  <b-col md="6">
                    <select class="form-control" v-model="projet.date_livraison.mois">
                      <option :value="null">Mois</option>
                      <option value="01">Janvier</option>
                      <option value="02">Fevrier</option>
                      <option value="03">Mars</option>
                      <option value="04">Avril</option>
                      <option value="05">Mai</option>
                      <option value="06">Juin</option>
                      <option value="07">Juillet</option>
                      <option value="08">Aout</option>
                      <option value="09">Septembre</option>
                      <option value="10">Octobre</option>
                      <option value="11">Novembre</option>
                      <option value="12">Decembre</option>
                    </select>
                  </b-col>
                  <b-col md="6">
                    <select class="form-control" v-model="projet.date_livraison.annee">
                      <option :value="null">Année</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                      <option value="2033">2033</option>
                      <option value="2034">2034</option>
                      <option value="2035">2035</option>
                    </select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('nb_etages')+':'">
                <b-form-input v-model.number="projet.nb_etages" type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('carre_etages')+':'">
                <b-form-input v-model.number="projet.carre_etages" type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('arnona_carre')+':'">
                <b-form-input v-model.number="projet.arnona_carre"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('vaad')+':'">
                <b-form-input v-model.number="projet.vaad"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('contact')+':'">
                <b-form-input v-model="projet.contact.nom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('first_name')+':'">
                <b-form-input v-model="projet.contact.prenom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('phone')+':'">
                <b-form-input v-model="projet.contact.telephone"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('email')+':'">
                <b-form-input v-model="projet.contact.mail"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('adresse-projet')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col>
              <b-form-group :label="this.$store.back.getText('google-autocomplete')+':'">
                <vue-google-autocomplete id="map" classname="form-control" placeholder="Adresse"  v-on:placechanged="getAddressData" country="il"> </vue-google-autocomplete>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="this.$store.back.getText('adresse')+':'">
                <b-form-input v-model="projet.adresse.route" type="text" required disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="this.$store.back.getText('numero')+':'">
                <b-form-input v-model.number="projet.adresse.street_number" type="number" required disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="this.$store.back.getText('ville')+':'">
                <b-form-input v-model="projet.adresse.locality" type="text" required disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="this.$store.back.getText('pays')+':'">
                <b-form-input v-model="projet.adresse.country" type="text" required disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="this.$store.back.getText('quartier')+':'">
                <select class="form-control" v-model.number="projet.adresse.quartier_id">
                  <option v-for="(q,i) in params.Quartier" :key="i" :value="q.id">{{q.titre.fr}}</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="card-deck mt-3">
      <div class="card card-custom gutter-b mt-3">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('list_proprietes')}}</h3>
          </div>
          <div class="card-toolbar">
            <a class="btn btn-icon btn-sm btn-success mr-1" v-b-modal.createPropriete >
              <i class="icon-md fas fa-plus"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Projet</th>
                <th scope="col">Client</th>
                <th scope="col">Type</th>
                <th scope="col">Statut</th>
                <th scope="col">Superficie</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p,i) in proprietes" :key="i">
                <td scope="row">
                  <a :href="'/#/propriete/detail/'+p.id">#{{p.id}}</a>
                </td>
                <td scope="row">
                  <a :href="'/#/projet/detail/'+p.projet.id">{{p.projet.titre}}</a>
                </td>
                <td scope="row">
                  <a :href="'/#/client/detail/'+p.client.id">{{p.client.nom}}</a>
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.ProprieteType,p.type_id)}}
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.ProprieteStatus,p.status_id)}}
                </td>
                <td scope="row">
                  {{p.superficie}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('commentaire')}}</h3>
        </div>
      </div>
      <div class="card-body">
        <b-form-group :label="this.$store.back.getText('commentaire')+':'">
          <b-form-textarea v-model="projet.commentaire" rows="4" readonly></b-form-textarea>
        </b-form-group>
        <b-form-group :label="this.$store.back.getText('ajouter')+':'">
          <b-input-group>
            <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
            <template #append>
              <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <b-modal id="addModel" :title="$store.back.getText('ajouter-model')" size="sm" ref="addModel">
      <div class="row">
        <div class="form-group col-md-12">
          <label>{{$store.back.getText('titre')}}:</label>
          <input type="text" class="form-control" v-model="modelTmpTitre" />
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addModel">
            {{$store.back.getText('sauvegarder')}}
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.addModel.hide();">
            {{$store.back.getText('fermer')}}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="addGamme" :title="$store.back.getText('ajouter-gamme')" size="sm" ref="addGamme">
      <div class="row">
        <div class="form-group col-md-12">
          <label>{{$store.back.getText('titre')}}:</label>
          <input type="text" class="form-control" v-model="gammeTmpTitre" />
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addGamme">
            {{$store.back.getText('sauvegarder')}}
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.addGamme.hide();">
            {{$store.back.getText('fermer')}}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="createPropriete" title="Creation d'une propriete" size="sm" ref="createPropriete">
      <div class="row">
        <div class="form-group col-md-12">
          <label>Client:</label>
          <model-select :options="params.Client" v-model.number="proprieteTmp.client_id" placeholder="Selectionnez votre client"></model-select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="createPropriete">
            {{$store.back.getText('sauvegarder')}}
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.createPropriete.hide();">
            {{$store.back.getText('fermer')}}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="import" title="Importation de document" ref="import">
      <b-form>
        <b-form-group label="Type:">
          <select class="form-control" v-model.number="tmpDoc.ged_type">
            <option v-for="(t,i) in params.GEDType" :key="i" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group label="Dociment:">
          <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button v-if="loaderImportFile === false" variant="primary" class="float-right" @click="addDoc()">
            Valide
          </b-button>
          <b-button v-else variant="info" size="sm" class="btn-icon mr-1 float-right"> <i class="fas fa-circle-notch fa-spin"></i></b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { ModelSelect } from 'vue-search-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  data(){
    return {
      ready:false,
      loaderImportFile:false,
      docs_filter:null,
      toggleFiles:false,
      tmpDoc:{
        "url": null,
        "ged_type": null,
        "date_create": null,
        "status": 1
      },
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      projet: {
        "id": null,
        "titre": null,
        "date_livraison": {
          "mois": null,
          "annee": null
        },
        "constructeur": null,
        "type_id": [],
        "adresse": {
          "street_number": null,
          "route": null,
          "locality": null,
          "administrative_area_level_1": null,
          "country": null,
          "latitude": null,
          "longitude": null,
          "quartier_id":null
        },
        "commentaire": null,
        "docs":[],
        "status_id": 1,
        "nb_etages":null,
        "carre_etages":null,
        "contact":{
          "nom":null,
          "prenom":null,
          "telephone":null,
          "mail":null,
        },
        "arnona_carre":null,//propriete auto calcule * surface
        "vaad":null,//propriete
        "status": 1,
        "date_create": null,
        "date_update": null,
      },
      params:{
        ProjetType:[],
        ProjetStatus:[],
        ProprieteStatus:[],
        ProprieteType:[],
        Client:[],
        GEDType:[],
        Quartier:[]
      },
      models:[],
      proprietes:[],
      modelSelectIndex:null,
      gammeSelectIndex:null,
      gammeType:["m2","fixe"],
      modelTmpTitre:null,
      gammeTmpTitre:null,
      proprieteTmp:{
        client_id: null,
        projet_id:null
      }
    };
  },
  components: {
    VueGoogleAutocomplete,
    ModelSelect,
    Treeselect
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
    modelSelectIndex(){
      this.gammeSelectIndex = null;
    }
  },
  methods: {
    save() {
      this.$store.back.ajax('/projet/create', this.projet, (res) => {
        if(res.status){
          if(this.projet.id === null){
            this.$router.push({path:'/projet/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.projet = res.data;
            this.$bvToast.toast(`Projet enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        }
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.projet.commentaire === null){
        this.projet.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.projet.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    getAddressData(addressData) {
      addressData.quartier_id = null;
      this.projet.adresse = addressData
    },
    addModel(){
      var params = {
        projet_id: this.projet.id,
        titre: this.modelTmpTitre
      }
      this.$store.back.ajax('/projet/model/create', params, (res) => {
        if(res.status){
          this.models = res.data;
        }
        this.modelTmpTitre = null;
        this.$refs.addModel.hide();
      });
    },
    addGamme(){
      var tmp = JSON.stringify({
        "titre":this.gammeTmpTitre,
        "detail":[]
      });
      tmp = JSON.parse(tmp)
      this.models[this.modelSelectIndex].gamme.push(tmp);
      this.gammeTmpTitre = null;
      this.$refs.addGamme.hide();
    },
    addQuote(){
      this.models[this.modelSelectIndex].gamme[this.gammeSelectIndex].detail.push({
        "titre": null,
        "quantite":1,
        "prix_achat": null,
        "prix_vente": null,
        "type": "m2"
      });
    },
    createPropriete(){
      this.proprieteTmp.projet_id = this.projet.id;
      this.$store.back.ajax('/propriete/create', this.proprieteTmp, (res) => {
        if(res.status){
          this.$router.push({path:'/propriete/detail/'+res.data.id});
        }
      })
    },
    getFileName(obj){
      var q = obj.url.slice(obj.url.indexOf("?")+1);
      var params = new URLSearchParams(q);
      var filename = params.get('q');
      filename = filename.slice(0,19-3)
      return filename+"...";
    },
    removeFile(index){
      this.projet.docs.splice(index,1);
    },
    handleFile(e){
      var the = this;
      this.loaderImportFile = true;
      var files = e.target.files;
      files.forEach((item) => {
        the.upladFile(item);
      });
    },
    upladFile(file){
      const fileUploaded = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.back.ajax('/ged/import', tmp, (res) => {
          if(res.status == true){
            this.tmpDoc.url = res.data;
            this.tmpDoc.date_create = Math.floor(Date.now() / 1000);
            this.loaderImportFile = false;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    addDoc(){
      var tmp = JSON.stringify(this.tmpDoc);
      tmp = JSON.parse(tmp)
      this.projet.docs.push(tmp);
      this.tmpDoc.url = null;
      this.tmpDoc.ged_type = null;
      this.tmpDoc.date_create = null;
      this.$refs.import.hide();
    }
  },
  computed: {
    totalVente(){
      var total = 0;
      this.models[this.modelSelectIndex].gamme[this.gammeSelectIndex].detail.forEach((item) => {
        total += parseFloat(item.prix_vente)*item.quantite
      });
      return total
    },
    totalAchat(){
      var total = 0;
      this.models[this.modelSelectIndex].gamme[this.gammeSelectIndex].detail.forEach((item) => {
        total += parseFloat(item.prix_achat)*item.quantite
      });
      return total
    },
    docsFiltered(){
      if(this.docs_filter === null){
        return this.projet.docs;
      }else{
        return this.projet.docs.filter(d => d.ged_type === this.docs_filter);
      }
    }
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/projet/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.projet = res.data.projet;
          this.models = res.data.models;
        }
      });
    }
    let params = {
      modules: ["ProjetType","ProjetStatus","ProprieteType","ProprieteStatus","Client","GEDType","Quartier"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.status = res.data['status'];
        res.data.ProjetType.forEach((item) => {
          item.label = item.titre.fr
        });
        this.params.ProjetType = res.data['ProjetType'];
        this.params.ProjetStatus = res.data['ProjetStatus'];
        this.params.ProprieteStatus = res.data['ProprieteStatus'];
        this.params.ProprieteType = res.data['ProprieteType'];
        this.params.GEDType = res.data['GEDType'];
        res.data['Client'].forEach(item => {
          item.text = item.nom+' '+item.prenom;
          item.value = item.id;
        });
        this.params.Client = res.data['Client'];
        this.params.Quartier = res.data['Quartier'];
        this.ready = true;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    var params = {
      field:"projet_id",
      value: this.$route.params.id
    }
    this.$store.back.ajax(`/propriete/other`, params, (res) => {
      if(res.status){
        this.proprietes = res.data;
      }
    });
  }
};
</script>
